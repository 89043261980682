import Vue from "vue"
import Router from "vue-router"
import envConfig from "@/utils/config"

Vue.use(Router)

/* Layout */
import Layout from "@/layout"

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },

  {
    path: "/neom_dashboard",
    component: () => import("@/views/neom_dashboard"),
    hidden: true,
    meta: {
      noAuth: true,
    },
  },

  {
    path: "/analytics/flights/3d/:id",
    component: () => import("@/views/analytics/flights/3d"),
    hidden: true,
  },

  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "System Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "System Dashboard", icon: "ri-dashboard-fill" },
      },
    ],
  },

  {
    path: "/library",
    component: Layout,
    meta: {
      title: "Library",
      icon: "ri-article-line",
      roles: ["Library Editor", "Library Viewer"],
    },
    children: [
      {
        path: "",
        name: "library-browse",
        component: () => import("@/views/library/browse"),
        meta: {
          title: "Library",
          breadcrumb: false,
          roles: ["Library Editor", "Library Viewer"],
        },
      },
      {
        hidden: true,
        path: "item",
        name: "library-item",
        component: () => import("@/views/library/item"),
        meta: {
          title: "Item",
          activeMenu: "/library",
          roles: ["Library Editor", "Library Viewer"],
        },
      },
      {
        hidden: true,
        path: "revision",
        name: "library-item-revision",
        component: () => import("@/views/library/item"),
        meta: {
          title: "Item",
          activeMenu: "/library",
          roles: ["Library Editor", "Library Viewer"],
        },
      },
    ],
  },
  {
    path: "/libraryitem",
    hidden: true,
    name: "library-item-print",
    component: () => import("@/views/library/print"),
    meta: {},
  },

  {
    path: "/flightplans",
    component: Layout,
    meta: {
      title: "Flight Plans",
      icon: "ri-booklet-fill",
      roles: ["Flight Plan Viewer", "Flight Plan Editor", "Flight Plan Runner"],
    },
    children: [
      {
        path: "",
        name: "flightplans-browse",
        component: () => import("@/views/flightplans/browse"),
        meta: {
          title: "Flight Plans",
          breadcrumb: false,
          roles: [
            "Flight Plan Viewer",
            "Flight Plan Editor",
            "Flight Plan Runner",
          ],
        },
      },
      {
        path: "plan",
        name: "flightplan",
        component: () => import("@/views/flightplans/plan"),
        meta: {
          title: "Flight Plan",
          roles: [
            "Flight Plan Viewer",
            "Flight Plan Editor",
            "Flight Plan Runner",
          ],
        },
        hidden: true,
      },
      {
        path: "executeplan",
        name: "flightplanrun",
        component: () => import("@/views/flightplans/plan"),
        meta: {
          title: "Execute Flight Plan",
          roles: [
            "Flight Plan Viewer",
            "Flight Plan Editor",
            "Flight Plan Runner",
          ],
        },
        hidden: true,
      },
    ],
  },

  {
    path: "/checklists",
    component: Layout,
    meta: {
      title: "Checklists",
      icon: "ri-list-check-2",
      roles: ["Checklist Editor", "Checklist Viewer"],
    },
    children: [
      {
        path: "",
        name: "checklists-browse",
        component: () => import("@/views/checklists/browse"),
        meta: {
          title: "Checklists",
          breadcrumb: false,
          roles: ["Checklist Editor", "Checklist Viewer"],
        },
      },
      {
        path: "checklist",
        name: "checklist",
        component: () => import("@/views/checklists/checklist"),
        meta: {
          title: "Checklist",
          roles: ["Checklist Editor", "Checklist Viewer"],
        },
        hidden: true,
      },
    ],
  },

  {
    path: "/fleet",
    component: Layout,
    redirect: "noRedirect",
    name: "Fleet Management",
    meta: {
      title: "Fleet Management",
      icon: "ri-send-plane-2-fill",
      roles: [
        "Vehicle Viewer",
        "Vehicle Editor",
        "Inventory Viewer",
        "Inventory Editor",
      ],
    },
    children: [
      {
        path: "vehicles",
        // redirect: "/fleet/vehicles/list",
        component: () => import("@/views/fleet/vehicles"),
        meta: {
          title: "Vehicles",
          roles: ["Vehicle Viewer", "Vehicle Editor"],
        },
        children: [
          {
            hidden: true,
            path: "stats",
            name: "vehicle-stats",
            component: () => import("@/views/fleet/vehicles/stats"),
            meta: {
              title: "Vehicle Stats",
              activeMenu: "/fleet/vehicles",
              roles: ["Vehicle Viewer", "Vehicle Editor"],
            },
          },
          {
            path: "",
            name: "vehicle-list",
            component: () => import("@/views/fleet/vehicles/list"),
            meta: {
              title: "Vehicles",
              icon: "ri-send-plane-2-line",
              breadcrumb: false,
              roles: ["Vehicle Viewer", "Vehicle Editor"],
            },
          },
          {
            hidden: true,
            path: "events",
            name: "vehicle-events",
            component: () => import("@/views/fleet/vehicles/events"),
            meta: {
              title: "Vehicle Events",
              activeMenu: "/fleet/vehicles",
              roles: ["Vehicle Viewer", "Vehicle Editor"],
            },
          },
          {
            hidden: true,
            path: "calendar",
            name: "vehicle-calendar",
            component: () => import("@/views/fleet/vehicles/calendar"),
            meta: {
              title: "Vehicle Calendar",
              activeMenu: "/fleet/vehicles",
              roles: ["Vehicle Editor", "Vehicle Viewer"],
            },
          },
          {
            hidden: true,
            path: "dashboard",
            name: "vehicle-dashboard",
            component: () => import("@/views/fleet/vehicles/dashboard"),
            meta: {
              title: "Vehicle Dashboard",
              activeMenu: "/fleet/vehicles",
              roles: ["Vehicle Viewer", "Vehicle Editor"],
            },
          },
          {
            hidden: true,
            path: "params",
            name: "vehicle-params",
            component: () => import("@/views/fleet/vehicles/params"),
            meta: {
              title: "Vehicle Params",
              activeMenu: "/fleet/vehicles",
              roles: ["Vehicle Viewer", "Vehicle Editor"],
            },
          },
        ],
      },
      {
        path: "vehiclemodels",
        name: "Vehicle Models",
        component: () => import("@/views/fleet/vehiclemodels"),
        meta: {
          title: "Vehicle Models",
          icon: "ri-send-plane-2-line",
          roles: ["Vehicle Viewer", "Vehicle Editor"],
        },
      },
      {
        path: "inventory",
        name: "Inventory",
        component: () => import("@/views/fleet/inventory"),
        meta: {
          title: "Inventory",
          icon: "ri-file-list-fill",
          roles: ["Inventory Viewer", "Inventory Editor"],
        },
      },
      {
        path: "inventorylots",
        name: "Inventory Lots",
        component: () => import("@/views/fleet/inventorylots"),
        meta: {
          title: "Inventory Lots",
          icon: "ri-file-list-fill",
          roles: ["Inventory Viewer", "Inventory Editor"],
        },
      },
      {
        path: "inventorycategories",
        name: "Inventory Categories",
        component: () => import("@/views/fleet/inventorycategories"),
        meta: {
          title: "Inventory Categories",
          icon: "ri-file-list-fill",
          roles: ["Inventory Viewer", "Inventory Editor"],
        },
      },
      {
        path: "conditioncriteria",
        name: "Condition Criteria Sets",
        component: () => import("@/views/fleet/conditioncriteria"),
        meta: {
          title: "Condition Criteria Sets",
          icon: "ri-check-double-line",
          roles: ["Inventory Viewer", "Inventory Editor"],
        },
      },
      {
        path: "vendors",
        name: "Vendors",
        component: () => import("@/views/fleet/vendors"),
        meta: {
          title: "Vendors",
          icon: "ri-global-fill",
          roles: ["Vendor Viewer", "Vendor Editor"],
        },
      },
    ],
  },

  {
    path: "/personnel",
    component: Layout,
    redirect: "noRedirect",
    children: [
      {
        path: "",
        name: "personnel",
        component: () => import("@/views/personnel/overview"),
        meta: {
          title: "Personnel",
          icon: "ri-team-fill",
          roles: ["Personnel Viewer", "Personnel Editor"],
        },
      },
      {
        hidden: true,
        path: "calendar",
        name: "personnel-calendar",
        component: () => import("@/views/personnel/calendar"),
        meta: {
          title: "Personnel Calendar",
          activeMenu: "/personnel",
          roles: ["Personnel Editor", "Personnel Viewer"],
        },
      },
    ],
  },

  {
    path: "/scheduling",
    component: Layout,
    redirect: "noRedirect",
    children: [
      {
        path: "",
        name: "scheduling",
        component: () => import("@/views/scheduling/index"),
        meta: {
          title: "Scheduling Calendar",
          icon: "ri-calendar-event-fill",
          roles: ["Schedule Viewer", "Schedule Editor"],
        },
      },
    ],
  },

  {
    path: "/analytics",
    component: Layout,
    redirect: "noRedirect",
    name: "Analytics",
    meta: {
      title: "Analytics",
      icon: "ri-line-chart-line",
      roles: ["Flight Log Viewer", "Flight Log Editor"],
    },
    children: [
      {
        path: "flights",
        // redirect: "/analytics/flights/browse",
        component: () => import("@/views/analytics/flights"),
        meta: {
          title: "Flight Logs",
          icon: "ri-profile-fill",
          roles: ["Flight Log Viewer", "Flight Log Editor"],
        },
        children: [
          {
            path: "",
            name: "flights-browse",
            component: () => import("@/views/analytics/flights/browse"),
            meta: {
              title: "Flight Logs",
              breadcrumb: false,
              roles: ["Flight Log Viewer", "Flight Log Editor"],
            },
          },
          {
            path: "multipass",
            name: "flights-multipass",
            component: () => import("@/views/analytics/flights/multipass"),
            meta: {
              title: "Multi-log Compare",
              activeMenu: "/analytics/flights",
              roles: ["Flight Log Viewer", "Flight Log Editor"],
            },
            hidden: true,
          },
          {
            path: "multireport",
            name: "flights-multireport",
            component: () => import("@/views/analytics/flights/multireport"),
            meta: {
              title: "Reporting",
              activeMenu: "/analytics/flights",
              roles: ["Flight Log Viewer", "Flight Log Editor"],
            },
            hidden: true,
          },
          {
            path: ":id",
            name: "flights-detail",
            component: () => import("@/views/analytics/flights/detail"),
            meta: {
              title: "Flight Log",
              activeMenu: "/analytics/flights",
              roles: ["Flight Log Viewer", "Flight Log Editor"],
            },
            hidden: true,
          },
          {
            path: "live/:vehicleId",
            name: "flights-live-view",
            component: () => import("@/views/analytics/flights/live"),
            meta: {
              title: "Flight View - Live",
              activeMenu: "/analytics/flights",
              roles: ["Live Status Viewer"],
            },
            hidden: true,
          },
        ],
      },
      {
        path: "upload",
        name: "flights-upload",
        component: () => import("@/views/analytics/flights/upload"),
        meta: {
          title: "Upload Flight Logs",
          icon: "ri-folder-upload-fill",
          roles: ["Flight Log Editor"],
        },
      },
    ],
  },

  {
    path: "/software",
    component: Layout,
    meta: {
      title: "Software",
      icon: "ri-download-cloud-fill",
      roles: ["Software Editor", "Software Viewer"],
    },
    children: [
      {
        path: "",
        name: "software-browse",
        component: () => import("@/views/software/browse"),
        meta: {
          title: "Software",
          breadcrumb: false,
          roles: ["Software Editor", "Software Viewer"],
        },
      },
      {
        hidden: true,
        path: "item",
        name: "software-item",
        component: () => import("@/views/software/item"),
        meta: {
          title: "Item",
          activeMenu: "/software",
          roles: ["Software Editor", "Software Viewer"],
        },
      },
    ],
  },

  {
    path: "/admin",
    component: Layout,
    redirect: "noRedirect",
    name: "Admin",
    meta: {
      title: "Admin",
      icon: "ri-tools-fill",
      roles: [
        "Project Viewer",
        "Project Editor",
        "Client Viewer",
        "Client Editor",
        "blueDelivery Manager",
        "Live Status Viewer",
      ],
    },
    children: [
      // {
      //   path: "settings",
      //   name: "Settings",
      //   component: () => import("@/views/admin/index"),
      //   meta: { title: "Settings", icon: "setting" },
      // },
      {
        path: "projects",
        name: "Projects",
        component: () => import("@/views/admin/projects"),
        meta: {
          title: "Projects",
          icon: "tickets",
          roles: ["Project Viewer", "Project Editor"],
        },
      },
      {
        path: "clients",
        name: "Clients",
        component: () => import("@/views/admin/clients"),
        meta: {
          title: "Clients",
          icon: "user",
          roles: ["Client Viewer", "Client Editor"],
        },
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/admin/users"),
        meta: {
          title: "Users",
          icon: "s-custom",
          roles: ["Sysadmin"],
        },
      },
      {
        path: "lists",
        name: "Data Lists",
        component: () => import("@/views/admin/datalists"),
        meta: {
          title: "Data Lists",
          icon: "document-copy",
          roles: ["Sysadmin"],
        },
      },
      // {
      //   path: "bluedelivery",
      //   name: "blueDelivery MGMT",
      //   component: () => import("@/views/admin/bluedelivery"),
      //   meta: {
      //     title: "blueDelivery MGMT",
      //     icon: "warning",
      //     roles: ["blueDelivery Manager"],
      //   },
      // },
      {
        path: "livestatus",
        name: "Live Vehicle Status",
        component: () => import("@/views/admin/livestatus"),
        meta: {
          title: "Live Vehicle Status",
          icon: "odometer",
          roles: ["Live Status Viewer"],
        },
      },
      // {
      //   path: "testdoc",
      //   name: "Test Editor",
      //   component: () => import("@/views/admin/testdoc"),
      //   meta: {
      //     title: "Test Editor",
      //     icon: "sugar",
      //   },
      // },
      // {
      //   path: "bftools",
      //   name: "Blueflite Engineering Tools",
      //   component: () => import("@/views/admin/bftools"),
      //   meta: {
      //     title: "Blueflite Engineering Tools",
      //     icon: "warning",
      //     roles: ["blueflite Admin"],
      //   },
      // },
    ],
  },

  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
]

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
